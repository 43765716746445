import shield from '../assets/images/products/shield/my_shield_logo.png';
import feva from '../assets/images/products/feva/Iveda_feva.png';
import pin from '../assets/images/products/pin/pin_point_logo.png';
import iveda from '../assets/images/products/iveda_ai/iveda_logo.png';
import clearview from '../assets/images/products/clearview/clearview_logo.png';
import vemo from '../assets/images/products/vemo/vl.png';
import cOL from "../assets/images/products/compliant/compL.png"
import zL from "../assets/images/products/zee/zL.png"
import acL from "../assets/images/products/active/aL.png"



const productItems = [
  {
    id: 'shield',
    img: shield,
    details:"non-toxic ( EPA Approved ) disinfectant spray"
  },
  {
    id: 'feva',
    img: feva,
    details:'Temperature Detection System'
  },
  {
    id: 'pin',
    img: pin,
    details:"Tracking System"
  },
  {
    id: 'iveda',
    img: iveda,
    details:"Artificial Intelligence System"
  },
  {
    id: 'clearview',
    img: clearview,
    details:"Mobile Security Smart Tower"
  },
  {
    id: 'vemo',
    img: vemo,
    details:"Body Cameras"
  },
  {
    id: 'compliant',
    img: cOL,
    details:"Police Security Technology"
  },
  {
    id: 'zee',
    img: zL,
    details:"Surveillance Cameras"
  },
  {
    id: 'active',
    img: acL,
    details:"Air Purification Systems"
  },

]
export default productItems